<template>
  <div class="club-card card">
    <div class="p-3 card-body">
      <div class="d-flex mt-n2">
        <div
          class="mt-n4 overflow-hidden flex-shrink-0"
        >
          <router-link
            :to="{
              name: 'edit-club',
              params: {
                id,
              },
            }"
            class="club-card__link stretched-link w-100 h-100"
          >
            <profile-picture 
              :width-and-height-in-pixels="75"
              :background-image-url="image"
            />
          </router-link>
        </div>
        <div class="my-auto ms-3">
          <h6 class="mb-0">{{ clubName }}</h6>
          
          <div
            v-if="isAdmin || boardMemberFunction"
            class="d-flex gap-2 mt-2"
          >
            <material-badge
              v-if="isAdmin"
              size="sm"
              color="warning"
            >
              Admin
            </material-badge>

            <material-badge
              v-if="boardMemberFunction"
              size="sm"
              color="secondary"
            >
              {{ boardMemberFunction }}
            </material-badge>

            <material-badge
              v-if="isActivated"
              size="sm"
              color="success"
            >
              Actief
            </material-badge>
          </div>
        </div>
        <div class="ms-auto">
          <dropdown-menu
            :items="[
              {
                handler: edit,
                name: 'Bewerken',
              },
              {
                handler: removeClub,
                name: 'Verwijderen',
              },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { remove } from '@/api/providers/club'
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import MaterialBadge from '@/components/MaterialBadge.vue';
import ProfilePicture from '../UI/ProfilePicture.vue';
import { createYesNoSweetAlert } from '../../helpers/sweetalert';

export default {
  name: 'ClubCard',

  components: {
    DropdownMenu,
    MaterialBadge,
    ProfilePicture
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
    isActivated: {
      type: Boolean,
      required: true,
    },
    clubName: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'remove',
  ],

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    edit() {
      this.$router.push({
        name: 'edit-club',
        params: {
          id: this.id
        },
      });
    },

    removeClub() {
      createYesNoSweetAlert(
        `Weet u zeker dat u club ${this.clubName} wilt verwijderen?`,
        async () => {
          try {
            await remove(this.id);
            this.$emit('remove', this.id);
            this.addNotification({
              description: `Club ${this.clubName} is verwijded.`,
              icon: {
                component: 'shield'
              }
            });
          }
          catch {
            this.addNotification({
              description: `Er is een fout opgetreden bij het verwijderen van de club.`,
              icon: {
                component: 'shield',
              },
            })
          }
        }
      )
    }
  },
};
</script>

<style scoped lang="scss">
.club-card {
  height: 100%;

  &__link {
    display: grid;
    place-content: center;
  }
}
</style>
